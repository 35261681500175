export const CHUA_LIEN_HE = 1;
export const XU_LY_DAU_VAO = 2;
export const KHONG_NGHE_MAY_LAN_1 = 3;
export const KHONG_NGHE_MAY_LAN_2 = 4;
export const KHONG_NGHE_MAY_LAN_3 = 5;
export const DONG_Y_HOC = 6;
export const DA_XU_LY_NHOM_ZALO = 7;
export const CHO_LOP_SAU = 8;
export const NGUNG_CUNG_CAP_DICH_VU = 9;
export const CHUA_MUON_HOC = 10;
export const HOC_KHOA_TIEP_THEO = 11;
export const KHONG_HOC_TIEP = 12;
export const THI_LAI = 13;
export const DA_XAC_NHAN_HOC_LEN_KHONG_CAM_KET = 14;
export const HOC_LAI = 15;
export const GIA_SU = 16;
export const PHUONG_AN_KHAC = 17;
export const DA_GUI_SMS = 18;
export const GOI_DIEN_THANH_CONG = 19;
export const HEN_GOI_LAI = 20
export const CHUA_XAC_NHAN_HOC_LEN_KHONG_CAM_KET = 21;

const statusManagement = [
    {
        value: CHUA_LIEN_HE,
        name: 'Chưa liên hệ'
    },
    {
        value: XU_LY_DAU_VAO,
        name: 'Xử lý đầu vào'
    },
    {
        value: KHONG_NGHE_MAY_LAN_1,
        name: 'Không nghe máy lần 1'
    },
    {
        value: KHONG_NGHE_MAY_LAN_2,
        name: 'Không nghe máy lần 2'
    },
    {
        value: KHONG_NGHE_MAY_LAN_3,
        name: 'Không nghe máy lần 3'
    },
    {
        value: DONG_Y_HOC,
        name: 'Đồng ý học'
    },
    {
        value: DA_XU_LY_NHOM_ZALO,
        name: 'Đã xử lý nhóm zalo'
    },
    {
        value: CHO_LOP_SAU,
        name: 'Chờ lớp sau'
    },
    {
        value: NGUNG_CUNG_CAP_DICH_VU,
        name: 'Ngừng cung cấp dịch vụ'
    },
    {
        value: CHUA_MUON_HOC,
        name: 'Chưa muốn học'
    },
    {
        value: HOC_KHOA_TIEP_THEO,
        name: 'Học khoá tiếp theo'
    },
    {
        value: KHONG_HOC_TIEP,
        name: 'Không học tiếp'
    },
    {
        value: THI_LAI,
        name: 'Thi lại'
    },
    {
        value: DA_XAC_NHAN_HOC_LEN_KHONG_CAM_KET,
        name: 'Đã xác nhận lên không cam kết'
    },
    {
        value: HOC_LAI,
        name: 'Học lại'
    },
    {
        value: GIA_SU,
        name: 'Gia sư'
    },
    {
        value: PHUONG_AN_KHAC,
        name: 'Phương án khác'
    },
    {
        value: DA_GUI_SMS,
        name: 'Đã gửi sms'
    },
    {
        value: GOI_DIEN_THANH_CONG,
        name: 'Gọi điện thành công'
    },
    {
        value: HEN_GOI_LAI,
        name: 'Hẹn gọi lại'
    },
    {
        value: CHUA_XAC_NHAN_HOC_LEN_KHONG_CAM_KET,
        name: 'Chưa xác nhận học lên không cam kết'
    },
]

const resultLastType1andStatus1 = [
    {
        value: CHUA_LIEN_HE,
        name: 'Chưa liên hệ'
    },
    {
        value: XU_LY_DAU_VAO,
        name: 'Xử lý đầu vào'
    },
    {
        value: KHONG_NGHE_MAY_LAN_1,
        name: 'Không nghe máy lần 1'
    },
    {
        value: KHONG_NGHE_MAY_LAN_2,
        name: 'Không nghe máy lần 2'
    },
    {
        value: KHONG_NGHE_MAY_LAN_3,
        name: 'Không nghe máy lần 3'
    },
    {
        value: HEN_GOI_LAI,
        name: 'Hẹn gọi lại'
    },
    {
        value: DONG_Y_HOC,
        name: 'Đồng ý học'
    },
]

const resultLastType1andStatus2 = [
    {
        value: DA_XU_LY_NHOM_ZALO,
        name: 'Đã xử lý nhóm zalo'
    },
    {
        value: CHO_LOP_SAU,
        name: 'Chờ lớp sau'
    },
    {
        value: NGUNG_CUNG_CAP_DICH_VU,
        name: 'Ngừng cung cấp dịch vụ'
    },
    {
        value: CHUA_MUON_HOC,
        name: 'Chưa muốn học'
    },
]

const resultLastType2andStatus1 = [
    {
        value: CHUA_LIEN_HE,
        name: 'Chưa liên hệ'
    },
]

const resultLastType2andStatus2 = [
    {
        value: HOC_KHOA_TIEP_THEO,
        name: 'Học khoá tiếp theo'
    },
    {
        value: KHONG_HOC_TIEP,
        name: 'Không học tiếp'
    },
]

const resultLastType3andStatus1 = [
    {
        value: CHUA_LIEN_HE,
        name: 'Chưa liên hệ'
    },
    {
        value: KHONG_NGHE_MAY_LAN_1,
        name: 'Không nghe máy lần 1'
    },
    {
        value: KHONG_NGHE_MAY_LAN_2,
        name: 'Không nghe máy lần 2'
    },
    {
        value: HEN_GOI_LAI,
        name: 'Hẹn gọi lại'
    },
    {
        value: CHUA_XAC_NHAN_HOC_LEN_KHONG_CAM_KET,
        name: 'Chưa xác nhận học lên không cam kết'
    },
]

const resultLastType3andStatus2 = [
    {
        value: DA_XAC_NHAN_HOC_LEN_KHONG_CAM_KET,
        name: 'Đã xác nhận lên không cam kết'
    },
    {
        value: NGUNG_CUNG_CAP_DICH_VU,
        name: 'Ngừng cung cấp dịch vụ'
    },
    {
        value: THI_LAI,
        name: 'Thi lại'
    },
    {
        value: HOC_LAI,
        name: 'Học lại'
    },
    {
        value: GIA_SU,
        name: 'Gia sư'
    },
    {
        value: PHUONG_AN_KHAC,
        name: 'Phương án khác'
    },
]

const resultLastType4andStatus1 = [
    {
        value: CHUA_LIEN_HE,
        name: 'Chưa liên hệ'
    },
    {
        value: HEN_GOI_LAI,
        name: 'Hẹn gọi lại'
    },
]

const resultLastType4andStatus2 = [
    {
        value: DA_GUI_SMS,
        name: 'Đã gửi sms'
    },
    {
        value: GOI_DIEN_THANH_CONG,
        name: 'Gọi điện thành công'
    },
]

const phoneNetwork = [
    {
        name: 'VinaPhone',
        value: 'VinaPhone',
    },
    {
        name: 'Viettel',
        value: 'Viettel',
    },
    {
        name: 'MobiFone',
        value: 'MobiFone',
    },
    {
        name: 'Vietnamobile',
        value: 'Vietnamobile',
    },
    {
        name: 'Itel',
        value: 'Itel',
    },
    {
        name: 'Gmobile',
        value: 'Gmobile',
    },
]

export {
    statusManagement,
    phoneNetwork,
    resultLastType1andStatus1,
    resultLastType1andStatus2,
    resultLastType2andStatus1,
    resultLastType2andStatus2,
    resultLastType3andStatus1,
    resultLastType3andStatus2,
    resultLastType4andStatus1,
    resultLastType4andStatus2,
}