import {
    CHO_LOP_SAU,
    CHUA_LIEN_HE,
    CHUA_MUON_HOC,
    DA_GUI_SMS,
    DA_XU_LY_NHOM_ZALO, DONG_Y_HOC,
    GIA_SU, GOI_DIEN_THANH_CONG, HEN_GOI_LAI,
    HOC_KHOA_TIEP_THEO,
    HOC_LAI,
    DA_XAC_NHAN_HOC_LEN_KHONG_CAM_KET,
    KHONG_HOC_TIEP,
    KHONG_NGHE_MAY_LAN_1,
    KHONG_NGHE_MAY_LAN_2,
    KHONG_NGHE_MAY_LAN_3,
    NGUNG_CUNG_CAP_DICH_VU,
    PHUONG_AN_KHAC,
    THI_LAI,
    XU_LY_DAU_VAO, CHUA_XAC_NHAN_HOC_LEN_KHONG_CAM_KET
} from "@/core/option/TaskManagementOption";

export class TaskManagementModel {
    constructor(id, selected, type, typeNum, status, lastResultNum, customerName, studentCode, phone, personInCharge, latestResult, createdAt, callSchedule, isCancel)  {
        this.id = id;
        this.selected = selected;
        this.type = type;
        this.typeNum = typeNum;
        this.status = status;
        this.lastResultNum = lastResultNum;
        this.customerName = customerName;
        this.studentCode = studentCode;
        this.phone = phone;
        this.personInCharge = personInCharge;
        this.latestResult = latestResult;
        this.createdAt = createdAt;
        this.callSchedule = callSchedule;
        this.isCancel = isCancel;
        this.bgType = this.setBgType(typeNum)
        this.bgStatus = this.setBgStatus(status, isCancel)
        this.bgLastResult = this.setBgLastResult(lastResultNum)
        this.isShowBtnDelete = this.checkShowButtonDelete(isCancel, status)
    }
    setBgType(type) {
        switch (type) {
            case 1:
                return 'bg-blue'
            case 2:
                return 'bg-green'
            case 3:
                return 'bg-yellow'
            case 4:
                return 'bg-violet'
        }
    }
    setBgStatus(status, isCancel) {
        if (isCancel === 1) {
            return 'bg-gray'
        }
        if (status === 'Hoàn thành') {
            return 'bg-teal'
        } else {
            return 'bg-yellow'
        }
    }
    setBgLastResult(lastResult) {
        switch (lastResult) {
            case HEN_GOI_LAI:
            case CHUA_LIEN_HE:
            case KHONG_NGHE_MAY_LAN_1:
            case KHONG_NGHE_MAY_LAN_2:
            case KHONG_NGHE_MAY_LAN_3:
            case CHUA_XAC_NHAN_HOC_LEN_KHONG_CAM_KET:
            case PHUONG_AN_KHAC:
                return 'bg-gray'
            case XU_LY_DAU_VAO:
            case HOC_LAI:
                return 'bg-blue'
            case CHO_LOP_SAU:
            case GIA_SU:
            case DA_GUI_SMS:
                return 'bg-yellow'
            case NGUNG_CUNG_CAP_DICH_VU:
            case CHUA_MUON_HOC:
            case THI_LAI:
            case KHONG_HOC_TIEP:
                return 'bg-red'
            case DA_XU_LY_NHOM_ZALO:
            case DA_XAC_NHAN_HOC_LEN_KHONG_CAM_KET:
            case HOC_KHOA_TIEP_THEO:
                return 'bg-green'
            case DONG_Y_HOC:
            case GOI_DIEN_THANH_CONG:
                return 'bg-primary'
        }
    }
    checkShowButtonDelete(isCancel, status) {
        if (isCancel) {
            return isCancel !== 1
        }
        return status !== 'Hoàn thành';
    }
}

export class CustomerModel {
    constructor(id, name) {
        this.id = id;
        this.value = name;
    }
}