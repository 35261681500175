<template>
  <div style="background: white; border-radius: 5px;">
    <HeaderTaskManagement @createTaskManagement="isCreate = true" :query="queryFilter"/>
    <hr style="padding: 0; margin: 0"/>
    <div style="padding:15px 20px" v-loading="loading">
      <div
           style="display: flex; flex-direction: column; gap: 15px; padding: 10px 0">
        <div class="grid-filter">
          <div class="display-flex-column">
            <span>Tác vụ</span>
            <el-input
                placeholder="Tìm theo mã tác vụ"
                suffix-icon="el-icon-search"
                v-model="queryFilter.id"
                @change="handleChangeFilter"
            >
            </el-input>
          </div>
          <div class="display-flex-column">
            <span>Khách hàng</span>
            <el-input
                placeholder="Tìm theo tên, số điện thoại KH"
                suffix-icon="el-icon-search"
                v-model="queryFilter.customer"
                @change="handleChangeFilter"
            >
            </el-input>
          </div>
          <div class="display-flex-column">
            <span>Loại tác vụ</span>
            <el-select v-model="queryFilter.typeTask" filterable clearable placeholder="Chọn trạng thái"
                       @change="handleChangeFilter" collapse-tags>
              <el-option
                  v-for="item in TYPE_TASK"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
              </el-option>
            </el-select>
          </div>
          <div class="display-flex-column">
            <span>Trạng thái</span>
            <el-select v-model="queryFilter.status" filterable clearable placeholder="Chọn trạng thái"
                       @change="handleChangeFilter" collapse-tags>
              <el-option
                  v-for="item in statusTask"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
              </el-option>
            </el-select>
          </div>
          <div class="display-flex-column">
            <span>Kết quả xử lý gần nhất</span>
            <el-select v-model="queryFilter.resultLast" filterable clearable multiple placeholder="Chọn kết quả xử lý"
                       @change="handleChangeFilter" collapse-tags>
              <el-option
                  v-for="item in statusManagementOption"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value">
              </el-option>
            </el-select>
          </div>
          <div class="display-flex-column">
            <span>Người phụ trách</span>
            <el-select v-model="queryFilter.personInCharge" filterable clearable multiple placeholder="Chọn người phụ trách"
                       @change="handleChangeFilter" collapse-tags>
              <el-option
                  v-for="item in listPersonInChargeOption"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
              </el-option>
            </el-select>
          </div>
          <div class="display-flex-column">
            <span>Trung tâm</span>
            <el-select v-model="queryFilter.center" style="width: 100%" filterable clearable
                       placeholder="Chọn trung tâm" @change="handleChangeFilter" collapse-tags>
              <el-option
                  v-for="item in centers"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
              </el-option>
            </el-select>
          </div>
          <div class="display-flex-column" v-if="queryFilter.center">
            <span>Chi nhánh</span>
            <el-select v-model="queryFilter.branch" style="width: 100%" multiple filterable clearable
                       placeholder="Chọn chi nhánh" @change="handleChangeFilter" collapse-tags>
              <el-option
                  v-for="item in branches"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
              </el-option>
            </el-select>
          </div>
          <div class="display-flex-column">
            <span>Nhà mạng</span>
            <el-select v-model="queryFilter.network" style="width: 100%" filterable clearable
                       placeholder="Chọn nhà mạng" @change="handleChangeFilter" collapse-tags>
              <el-option
                  v-for="item in phoneNetworkOption"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value">
              </el-option>
            </el-select>
          </div>
          <div class="display-flex-column">
            <span>Thời gian tạo</span>
            <el-date-picker
                style="width: 100%"
                v-model="queryFilter.createdAt"
                type="daterange"
                align="right"
                start-placeholder="Thời gian bắt đầu"
                end-placeholder="Thời gian kết thúc"
                format="dd-MM-yyyy"
                value-format="yyyy-MM-dd"
                :default-value="new Date()"
                @change="handleChangeFilter"
            >
            </el-date-picker>
          </div>
          <div class="display-flex-column">
            <span>Lịch gọi</span>
            <el-date-picker
                style="width: 100%"
                v-model="queryFilter.callCalendar"
                type="daterange"
                align="right"
                start-placeholder="Thời gian bắt đầu"
                end-placeholder="Thời gian kết thúc"
                format="dd-MM-yyyy"
                value-format="yyyy-MM-dd"
                :default-value="new Date()"
                @change="handleChangeFilter"
            >
            </el-date-picker>
          </div>
          <div class="display-flex-column" v-if="queryFilter.typeTask === XEP_LOP">
            <span>Lịch học mong muốn</span>
            <el-select v-model="queryFilter.schedule" filterable clearable placeholder="Chọn trạng thái"
                       @change="handleChangeFilter">
              <el-option
                  v-for="item in listSchedule"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
              </el-option>
            </el-select>
          </div>
          <div class="display-flex-column" v-if="queryFilter.typeTask === XEP_LOP">
            <span>Khóa học cần xếp</span>
            <el-select v-model="queryFilter.course" filterable clearable placeholder="Chọn trạng thái"
                       @change="handleChangeFilter">
              <el-option
                  v-for="item in listCourse"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
              </el-option>
            </el-select>
          </div>
        </div>
      </div>
      <div v-if="taskSelectedList.length > 0 && (currentUser.user.permission.includes(permission.DIEU_PHOI_TAC_VU_DIEU_PHOI_LOP_HOC) ||  currentUser.user.permission.includes(permission.QUAN_LY_TAC_VU_DIEU_PHOI_LOP_HOC))" style="margin: 20px 0; display: flex; align-items: center; gap: 20px">
        <div style="margin: 20px 0; display: flex; align-items: center; gap: 20px" v-if="currentUser.user.permission.includes(permission.DIEU_PHOI_TAC_VU_DIEU_PHOI_LOP_HOC) ||  currentUser.user.permission.includes(permission.QUAN_LY_TAC_VU_DIEU_PHOI_LOP_HOC)">
          <span>Đã chọn ({{ taskSelectedList.length }})</span>
          <el-button type="success" style="background: #500DA7; color: white; border: 0"
                     @click="isAddPersonInCharge = true">
            <i class="el-icon-s-promotion" style="color: white"></i> Điều phối công việc
          </el-button>
          <el-button type="info" style="background: #E6E6E6; border: 0; display: flex; align-items: center"
                     @click="cancelSelected">
            <i class="el-icon-close" style="color: black"></i> Hủy
          </el-button>
        </div>
      </div>
      <div class="table-responsive mt-3" style="overflow: hidden; padding-bottom: 30px">
        <table class="table" style="text-align: center;">
          <thead>
          <tr style="border: 1px solid #EBEDF3">
            <th scope="col" class="w-[40px]" style="background: #F8F8F8">
              <el-checkbox v-model="isSelectedAll" :disabled="disableSelectedAll"
                           @change="selectedAllTask"></el-checkbox>
            </th>
            <th scope="col" class="w-[40px]" style="background: #F8F8F8; text-align: start">Mã tác vụ</th>
            <th scope="col" class="min-w-[80px]" style="background: #F8F8F8;white-space: nowrap; text-align: start">Phân loại</th>
            <th scope="col" class="min-w-[40px]" style="background: #F8F8F8; text-align: start;">Mã học viên</th>
            <th scope="col" class="min-w-[80px]" style="background: #F8F8F8; text-align: start">Họ tên khách hàng</th>
            <th scope="col" class="min-w-[80px]" style="background: #F8F8F8; text-align: start; white-space: nowrap">Số điện thoại</th>
            <th scope="col" class="min-w-[80px]" style="background: #F8F8F8; text-align: start; white-space: nowrap">Trạng thái</th>
            <th scope="col" class="min-w-[120px]" style="background: #F8F8F8; text-align: start; white-space: nowrap">Kết quả xử lý gần nhất</th>
            <th scope="col" class="min-w-[150px]" style="background: #F8F8F8; text-align: start">Người phụ trách</th>
            <th scope="col" class="min-w-[180px]" style="background: #F8F8F8; white-space: nowrap; text-align: start">Thời gian tạo</th>
            <th scope="col" class="min-w-[180px]" style="background: #F8F8F8; text-align: start">Lịch gọi</th>
            <th scope="col" class="w-100px" style="background: #F8F8F8">Hành động</th>
          </tr>
          </thead>
          <tbody v-if="loading">
          <tr class="col-12">
            <td class="text-center">
              <i class="el-icon-loading" style="position: absolute; left: 50%; margin: 15px 0"></i>
            </td>
          </tr>
          </tbody>
          <tbody v-else-if="taskManagementList?.length > 0">
          <tr v-for="item of taskManagementList" :key="item?.id" style="border: 1px solid #EBEDF3; border-top: 0">
            <td>
              <el-checkbox v-model="item.selected" :disabled="!item?.isShowBtnDelete" @change="getTaskSelectedList"></el-checkbox>
            </td>
            <td style="text-align: start; text-transform: uppercase">{{ item?.id }}</td>
            <td style="text-align: start; ">
              <span style="padding: 4px 8px; border-radius: 9999px; white-space: nowrap; word-break: break-word" :class="item?.bgType">{{ item?.type }}</span>
            </td>
            <td style="display: flex; justify-content: start; border-top: 0 !important;">{{ item?.studentCode + 100000 }}</td>
            <td><p class="line-clamp-1" style="white-space: pre-wrap; text-align: start" v-html="item.customerName"></p></td>
            <td style="text-align: start">{{ item?.phone }}</td>
            <td style="text-align: start">
              <span style="padding: 4px 8px; border-radius: 5px; border: 0; white-space: nowrap; word-break: break-word" :class="item?.bgStatus">{{item.isCancel === 1 ? 'Hủy' : item?.status}}</span>
            </td>
            <td style="text-align: start">
              <span style="padding: 4px 8px; border-radius: 5px; border: 0; white-space: nowrap; word-break: break-word" :class="item?.bgLastResult">{{ item?.latestResult }}</span>
            </td>
            <td style="text-align: start">{{ item?.personInCharge }}</td>
            <td style="text-align: start">{{ item?.createdAt }}</td>
            <td style="text-align: start">{{ item?.callSchedule }}</td>
            <td>
              <router-link v-if="currentUser.user.permission.includes(permission.XEM_CHI_TIET_TAC_VU_LOP_HOC) ||  currentUser.user.permission.includes(permission.QUAN_LY_TAC_VU_DIEU_PHOI_LOP_HOC)" :to="{ name: 'TaskManagementDetail', params: { id: item?.id } }" title="Xem"
                           class="btn btn-icon btn-xs btn-outline-info"><i class="fas far fa-eye"></i>
              </router-link>
              <button v-if="item?.isShowBtnDelete && (currentUser.user.permission.includes(permission.HUY_TAC_VU_DIEU_PHOI_LOP_HOC)||  currentUser.user.permission.includes(permission.QUAN_LY_TAC_VU_DIEU_PHOI_LOP_HOC))" class="btn btn-icon btn-xs btn-outline-danger ml-2" title="Hủy tác vụ"
                      @click="onDeleteTask(item)">
                <i class="el-icon-delete"></i>
              </button>
            </td>
          </tr>
          </tbody>
          <tbody v-else>
          <tr style="word-break: break-word; white-space: nowrap; position: relative">
            <label style="position: absolute; left: 50%; translate: -50%; top: 10px">Chưa có dữ liệu</label>
          </tr>
          </tbody>
        </table>
        <div v-if="taskManagementList?.length > 0">
          <div class="edu-paginate mx-auto text-center mt-10 mb-5"
               style="display: flex; justify-content: center; align-items: center; height: 50px">
            <paginate
                v-model="page"
                :page-count="lastPage"
                :page-range="3"
                :margin-pages="1"
                :click-handler="clickCallback"
                :prev-text="'Trước'"
                :next-text="'Sau'"
                :container-class="'pagination b-pagination'"
                :pageLinkClass="'page-link'"
                :next-link-class="'next-link-item'"
                :prev-link-class="'prev-link-item'"
                :prev-class="'page-link'"
                :next-class="'page-link'"
                :page-class="'page-item'"
            >
            </paginate>
          </div>
          <div class="w-100" style="display: flex; justify-content: end; margin-top: -50px">
            <div class="mr-9">
              Tổng số học viên: <span
                style="color: #0066FF; font-weight: 600; margin-left: 5px;">{{
                this.totalStudent
              }}</span>
            </div>
            <div>
              Tổng số bản ghi: <span
                style="color: #0066FF; font-weight: 600; margin-left: 5px;">{{
                this.total ?? taskManagementList.length
              }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog title="Thêm mới tác vụ" :show-close="false" :visible.sync="isCreate" v-if="isCreate">
      <hr style="padding: 0; margin-top: -20px"/>
      <DialogAddTaskManagement @closeDialog="isCreate = false" @getListTaskManagement="getTaskManagementList"/>
    </el-dialog>
    <el-dialog
        title="Điều phối công việc" :show-close="false"
        :visible.sync="isAddPersonInCharge"
        @getTaskManagementList="getTaskManagementList"
        v-if="isAddPersonInCharge"
    >
      <hr style="padding: 0; margin-top: -20px"/>
      <DialogAddPersonInCharge
          @closeDialog="isAddPersonInCharge = false"
          :list-task-selected="taskSelectedList"
          @workCoordinationSuccess="workCoordinationSuccess"
          @getTaskManagementList="getTaskManagementList"
      />
    </el-dialog>
    <el-dialog :show-close="false" :visible.sync="isDelete" width="40%" v-if="isDelete">
      <template #title>
        <span style="color: red; font-weight: 500; font-size: 15px">Hủy tác vụ</span>
      </template>
      <hr style="padding: 0; margin-top: -20px"/>
      <DialogCancelTask
          :id-task="taskDelete?.id"
          @closeDialog="isDelete = false"
          @getListTaskManagement="getTaskManagementList"/>
    </el-dialog>
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import {
  GET_ALL_PERSON_IN_CHARGE,
  GET_BRANCHES, GET_CENTER_TAG_E, GET_COURSE_LIST,
  GET_SCHEDULE_LIST,
  GET_TASK_MANAGEMENT_LIST,
} from "@/core/services/store/task-management/task-management.module";
import {
  TaskManagementModel,
} from "@/view/pages/task-management/model/TaskManagementModel";
import DialogAddTaskManagement from "@/view/pages/task-management/components/DialogAddTaskManagement.vue";
import HeaderTaskManagement from "@/view/pages/task-management/components/HeaderTaskManagement.vue";
import DialogAddPersonInCharge from "@/view/pages/task-management/components/DialogAddPersonInCharge.vue";
import DialogCancelTask from "@/view/pages/task-management/components/DialogCancelTask.vue";
import {STATUS_TASK, TYPE_TASK, XEP_LOP} from "@/core/option/taskArrangePersonal";
import {
  phoneNetwork,
  resultLastType1andStatus1,
  resultLastType1andStatus2,
  resultLastType2andStatus1,
  resultLastType2andStatus2,
  resultLastType3andStatus1,
  resultLastType3andStatus2, resultLastType4andStatus1, resultLastType4andStatus2,
  statusManagement
} from "@/core/option/TaskManagementOption";
import {mapGetters} from "vuex";

export default {
  name: "TaskManagerList",
  components: {DialogCancelTask, DialogAddPersonInCharge, HeaderTaskManagement, DialogAddTaskManagement},
  data() {
    return {
      loading: true,
      page: 1,
      lastPage: 1,
      total: 0,
      taskManagementList: [],
      addTask: false,
      isCreate: false,
      isDelete: false,
      taskDelete: null,
      isAddPersonInCharge: false,
      listPersonInChargeOption: [],
      statusManagementOption: statusManagement,
      phoneNetworkOption: phoneNetwork,
      branches: [],
      centers: [],
      isSelectedAll: false,
      taskSelectedList: [],
      disableSelectedAll: false,
      queryFilter: {
        id: null,
        customer: null,
        status: null,
        resultLast: [],
        personInCharge: [],
        center: null,
        branch: [],
        network: null,
        createdAt: [],
        callCalendar: [],
        typeTask: null,
        schedule: null,
        course: null
      },
      totalStudent: 0,
      listSchedule: [],
      listCourse: [],
      TYPE_TASK,
      statusTask: STATUS_TASK,
      XEP_LOP
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Danh sách quản lý tác vụ", name: 'TaskManagerList'},
      {title: "Quản lý tác vụ điều phối xếp lớp"}
    ]);
    this.getTaskManagementList()
    this.getListSchedule()
    this.getAllPersonInCharge()
    this.getCenterTagE()
  },
  methods: {
    selectedAllTask() {
      if (this.isSelectedAll) {
        this.taskManagementList.filter(item => !item.selected && item.isShowBtnDelete).forEach(item => item.selected = true);
        this.taskSelectedList = this.taskManagementList.filter(item => item.selected)
      } else {
        this.taskManagementList.filter(item => item.selected && item.isShowBtnDelete).forEach(item => item.selected = false);
        this.taskSelectedList = []
      }
    },
    getAllPersonInCharge() {
      const payload = {is_all: 1}
      this.$store.dispatch(GET_ALL_PERSON_IN_CHARGE, payload).then(res => {
        if (!res.error) {
          this.listPersonInChargeOption = res.data ?? []
        }
      }).catch(e => {
        console.log(e, 'Error get list personInCharge')
      })
    },
    cancelSelected() {
      this.taskManagementList.filter(item => item.selected)
      this.taskManagementList = this.taskManagementList.map(task => {
        return {...task, selected: false};
      })
      this.taskManagementList.filter(item => item.selected).forEach((item) => item.selected = false);
      this.taskSelectedList = []
      this.isSelectedAll = false;
    },
    clickCallback(obj) {
      this.page = obj;
      this.getTaskManagementList()
    },
    getListResultLast(obj) {
      switch (obj.typeTask) {
        case 1:
          if (obj.status === 1) {
            this.statusManagementOption = resultLastType1andStatus1;
          } else {
            this.statusManagementOption = resultLastType1andStatus2;
          }
          break;
        case 2:
          if (obj.status === 1) {
            this.statusManagementOption = resultLastType2andStatus1;
          } else {
            this.statusManagementOption = resultLastType2andStatus2;
          }
          break;
        case 3:
          if (obj.status === 1) {
            this.statusManagementOption = resultLastType3andStatus1;
          } else {
            this.statusManagementOption = resultLastType3andStatus2;
          }
          break;
        case 4:
          if (obj.status === 1) {
            this.statusManagementOption = resultLastType4andStatus1;
          } else {
            this.statusManagementOption = resultLastType4andStatus2;
          }
          break;
        default:
          this.statusManagementOption = statusManagement;
      }
    },
    async handleChangeFilter() {
      if (this.queryFilter.center) {
        this.getBranches(this.queryFilter.center);
      }
      this.getListCourse(this.queryFilter.center)
      if (this.queryFilter.typeTask && this.queryFilter.status) {
        this.getListResultLast(this.queryFilter)
      }
      await this.queryFilterTask();
      await this.getTaskManagementList();
    },
    callApi() {
      const payload = {
        page: this.page,
        id: this.queryFilter.id,
        keyword: this.queryFilter.customer,
        status: this.queryFilter.status ? +this.queryFilter.status : null,
        result_lastest: this.queryFilter.resultLast,
        center_id: this.queryFilter.center ? this.queryFilter.center : null,
        branch_ids: this.queryFilter.branch,
        person_in_charge_ids: this.queryFilter.personInCharge,
        network: this.queryFilter.network ?? null,
        date_range_created: this.queryFilter.createdAt,
        date_range_call_time: this.queryFilter.callCalendar,
        type: this.queryFilter.typeTask,
        desired_schedule: this.queryFilter.schedule,
        course_id: this.queryFilter.course
      }
      this.$store.dispatch(GET_TASK_MANAGEMENT_LIST, payload).then(res => {
        if (!res.error) {
          this.loading = true
          this.page = res.pagination.current_page
          this.lastPage = res.pagination.last_page
          this.total = res.pagination.total
          this.taskManagementList = res.data.map(item => {
            return new TaskManagementModel(
                item?.id, false, item?.type, item?.type_num, item?.status, item?.status_num, item?.user_name, item?.user_id,
                item?.phone, item?.person_in_charge, item?.latest_result, item?.created_at, item?.call_time_newest, item?.is_cancel)
          }) ?? []
          this.totalStudent = res.total_user
        }
      }).catch(e => {
        console.log(e, 'error')
      }).finally(() => {
        this.loading = false
      })
    },
    getCenterTagE() {
      const payload  = {
        limit: 10000,
        is_edutalk: 1,
        status: 1,
      }
      this.$store.dispatch(GET_CENTER_TAG_E, payload).then(res => {
        if (!res.error) {
          this.centers = res.data?.data
        }
      })
    },
    getTaskSelectedList() {
      this.taskSelectedList = this.taskManagementList.filter(item => item.selected)
    },
    async getTaskManagementList() {
      await this.getParams()
      this.callApi()
    },
    workCoordinationSuccess() {
      this.taskSelectedList = []
      this.isSelectedAll = false
    },
    onDeleteTask(task) {
      this.isDelete = true
      this.taskDelete = task
    },
    getBranches(centerId) {
      const payload = {
        limit: 10000,
        center_id: centerId
      }
      this.$store.dispatch(GET_BRANCHES, payload).then(res => {
        if (!res.error) {
          this.branches = res.data?.data
        }
      })
    },
    getParams() {
      this.queryFilter.id = this.$route.query.id ?? null
      this.queryFilter.keyword = this.$route.query.keyword ?? null
      this.queryFilter.status = this.$route.query.status ? parseInt(this.$route.query.status) : null
      this.queryFilter.resultLast = this.$route.query.result_lastest ? JSON.parse(`[${this.$route.query.result_lastest}]`) : []
      this.queryFilter.personInCharge = this.$route.query.person_in_charge_ids ? JSON.parse(`[${this.$route.query.person_in_charge_ids}]`) : []
      this.queryFilter.center = this.$route.query.center_id ? parseInt(this.$route.query.center_id) : null
      this.queryFilter.branch = this.$route.query.branch_ids ? JSON.parse(`[${this.$route.query.branch_ids}]`) : null
      this.queryFilter.network = this.$route.query.network ?? null
      this.queryFilter.createdAt = this.$route.query.date_range_created ?? []
      this.queryFilter.callCalendar = this.$route.query.date_range_call_time ?? []
      this.queryFilter.typeTask = this.$route.query.type ? parseInt(this.$route.query.type) : null
      if (+this.queryFilter.typeTask === XEP_LOP) {
        this.queryFilter.course = this.$route.query.course_id ? parseInt(this.$route.query.course_id) : null
        this.queryFilter.schedule = this.$route.query.desired_schedule ? parseInt(this.$route.query.desired_schedule) : null
      }
      return null
    },
    async queryFilterTask() {
      await this.$router.push({
        name: 'TaskManagementList',
        query: {
          id: this.queryFilter.id,
          keyword: this.queryFilter.customer,
          status: this.queryFilter.status,
          result_lastest: this.queryFilter.resultLast,
          center_id: this.queryFilter.center,
          branch_ids: this.queryFilter.branch,
          person_in_charge_ids: this.queryFilter.personInCharge,
          network: this.queryFilter.network,
          date_range_created: this.queryFilter.createdAt,
          date_range_call_time: this.queryFilter.callCalendar,
          type: this.queryFilter.typeTask,
          desired_schedule: this.queryFilter.schedule,
          course_id: this.queryFilter.course
        }
      })
      return null
    },
    getListSchedule() {
      this.$store.dispatch(GET_SCHEDULE_LIST).then((data) => {
        this.listSchedule = data.data;
      })
    },
    getListCourse(centerId) {
      this.$store.dispatch(GET_COURSE_LIST, {center_id: centerId}).then((data) => {
        this.listCourse = data.data;
      })
    }
  },
  computed: {
    ...mapGetters(["currentUser"]),
  }
}
</script>

<style scoped>
.display-flex-column {
  display: flex;
  flex-direction: column;
  gap: 6px;
}
.mx-datepicker-popup {
  z-index: 9999999 !important;
}

.bg-violet {
  background: #B300C2;
  color: white;
}

.bg-type2 {
  background: #007499;
  color: white;
}

.bg-yellow {
  background: #FFB800;
  color: black;
}

.bg-blue {
  background: #0066FF;
  color: white;
}

.bg-amber {
  background: #856000;
  color: white;
}

.bg-red {
  background: #FF0000;
  color: white;
}

.bg-green {
  background: #038200;
  color: white;
}

.bg-green-500 {
  background: #006B04;
  color: white;
}

.bg-gray {
  background: #D2D2D2;
  color: black;
}

.bg-teal {
  background: #1BC5BD;
  color: white;
}

.bg-primary {
  background: #4C0192;
  color: white;
}

.grid-filter {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 10px 60px;
}

@media screen and (max-width: 1560px) {
  .grid-filter {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 10px 30px;
  }
}
</style>