import {
    CHO_LOP_SAU,
    CHUA_LIEN_HE,
    CHUA_MUON_HOC,
    DA_GUI_SMS,
    DA_XU_LY_NHOM_ZALO,
    DONG_Y_HOC,
    GIA_SU,
    GOI_DIEN_THANH_CONG,
    HEN_GOI_LAI,
    HOC_KHOA_TIEP_THEO,
    HOC_LAI,
    DA_XAC_NHAN_HOC_LEN_KHONG_CAM_KET,
    KHONG_HOC_TIEP,
    KHONG_NGHE_MAY_LAN_1,
    KHONG_NGHE_MAY_LAN_2,
    KHONG_NGHE_MAY_LAN_3,
    NGUNG_CUNG_CAP_DICH_VU,
    PHUONG_AN_KHAC,
    THI_LAI,
    XU_LY_DAU_VAO, CHUA_XAC_NHAN_HOC_LEN_KHONG_CAM_KET
} from "@/core/option/TaskManagementOption";

export const NEAR_RESULT_DEFINE = {
    1: 'Chưa liên hệ',
    2: 'Không nghe máy lần 1',
    3: 'Không nghe máy lần 2',
    4: 'Không nghe máy lần 3',
    5: 'Đồng ý học',
    6: 'Đã xử lý nhóm zalo',
    7: 'Chờ lớp sau',
    8: 'Ngừng cung cấp dịch vụ',
    9: 'Chưa muốn học',
    10: 'Khóa học tiếp theo',
    11: 'Không học tiếp',
    12: 'Thi lại',
    13: "Học lên không cam kết",
    14: "Học lại",
    15: "Gia sư",
    16: "Phương án khác",
    17: "Học viên nhận thông tin"
};

export const NEAR_RESULT_DEFINE_ARRAY = [{
    id: KHONG_NGHE_MAY_LAN_1,
    name: 'Không nghe máy lần 1'
}, {id: KHONG_NGHE_MAY_LAN_2, name: 'Không nghe máy lần 2'}, {
    id: KHONG_NGHE_MAY_LAN_3,
    name: 'Không nghe máy lần 3'
}, {id: DONG_Y_HOC, name: 'Đồng ý học'}, {id: DA_XU_LY_NHOM_ZALO, name: 'Đã xử lý nhóm zalo'}, {
    id: CHO_LOP_SAU,
    name: 'Chờ lớp sau'
}, {id: NGUNG_CUNG_CAP_DICH_VU, name: 'Ngừng cung cấp dịch vụ'}, {
    id: CHUA_MUON_HOC,
    name: 'Chưa muốn học'
}, {id: XU_LY_DAU_VAO, name: 'Xử lý đầu vào'}, {id: HEN_GOI_LAI, name: 'Hẹn gọi lại'},];
export const FAIL_COURSE_RESULT_ARRAY = [
    {id: THI_LAI, name: 'Thi lại'},
    {id: DA_XAC_NHAN_HOC_LEN_KHONG_CAM_KET, name: 'Đã xác nhận lên không cam kết'},
    {id: CHUA_XAC_NHAN_HOC_LEN_KHONG_CAM_KET, name: 'Chưa xác nhận học lên không cam kết'},
    {id: HOC_LAI, name: 'Học lại'},
    {id: GIA_SU, name: 'Gia sư'},
    {id: PHUONG_AN_KHAC, name: 'Phương án khác'},
    {id: KHONG_NGHE_MAY_LAN_1, name: 'Không nghe máy lần 1'}, {
    id: KHONG_NGHE_MAY_LAN_2,
    name: 'Không nghe máy lần 2'
}, {id: NGUNG_CUNG_CAP_DICH_VU, name: 'Ngừng cung cấp dịch vụ'}, {id: HEN_GOI_LAI, name: 'Hẹn gọi lại'},];
export const PASS_COURSE_RESULT_DEFINE_ARRAY = [{
    id: HOC_KHOA_TIEP_THEO,
    name: 'Học khóa tiếp theo'
}, {id: KHONG_HOC_TIEP, name: 'Không học tiếp'},];
export const OPENING_RESULT_DEFINE_ARRAY = [{id: DA_GUI_SMS, name: 'Đã gửi SMS'}, {
    id: GOI_DIEN_THANH_CONG,
    name: 'Gọi điện thành công'
}, {id: HEN_GOI_LAI, name: 'Hẹn gọi lại'},];

export const TYPE_TASK_DIFINE = {
    1: "xếp lớp", 3: "Xử lý faill khóa", 2: "Xử lý pass khóa", 4: "Khai giảng",
};
export const XEP_LOP = 1;
export const XU_LY_FALL_KHOA = 3;
export const XU_LY_PASS_KHOA = 2;
export const KHAI_GIANG = 4;
export const ALL_HISTORY = 0

export const TYPE_TASK = [{
    id: XEP_LOP, name: "Xếp lớp"
}, {
    id: XU_LY_PASS_KHOA, name: "Xử lý pass khóa"
}, {
    id: XU_LY_FALL_KHOA, name: "Xử lý fail khóa"
}, {
    id: KHAI_GIANG, name: "Khai giảng"
},]

export const STATUS_TASK = [{id: 1, name: 'Cần làm'}, {id: 2, name: 'Hoàn thành'}, {id: 3, name: 'Huỷ'},];
